import { baseErrorHandler, bookMeetingHandler } from 'errors/ErrorHandlers'

import { api } from 'config/api'
import { Location } from 'lib/meeting-location'

import { MeetingRoomFromApi } from 'services/api/MeetingRooms'
import { BookableService } from 'services/auth/ssp'

import { Endpoint } from '.'
import { HostUser, MeetingInvitation, MeetingParticipant, QuickMeetingHost } from './Users'

const MeetingsEndpoints = {
  listHosted: (listEvents = false): Endpoint => {
    const query = listEvents ? '?list_events=true' : ''
    return {
      method: 'GET',
      url: `meetings/index-hosted${query}`,
    }
  },
  listParticipated: (listEvents = false): Endpoint => {
    const query = listEvents ? '?list_events=true' : ''
    return {
      method: 'GET',
      url: `meetings/index-participated${query}`,
    }
  },
  listUpcomingProviderEvents: (hostId: string): Endpoint => {
    return {
      method: 'GET',
      url: `meetings/list-upcoming-provider-events/${hostId}`,
    }
  },
  listParticipatedOfHost: (hostId: string): Endpoint => {
    return {
      method: 'GET',
      url: `meetings/index-participated/?host_id=${hostId}`,
    }
  },
  viewHosted: (meetingId: string): Endpoint => {
    return {
      method: 'GET',
      url: `meetings/view-hosted/${meetingId}`,
      errorHandler: baseErrorHandler,
    }
  },
  viewByUrl: (url?: string): Endpoint => {
    if (!url) return {}
    return {
      method: 'GET',
      url: `meetings/view?url=${encodeURIComponent(url)}`,
    }
  },
  viewParticipated: (meetingId: string): Endpoint => {
    return {
      method: 'GET',
      url: `meetings/view-participated/${meetingId}`,
      errorHandler: baseErrorHandler,
    }
  },
  view: (meetingId: string): Endpoint => {
    return {
      method: 'GET',
      url: `meetings/view/${meetingId}`,
      errorHandler: baseErrorHandler,
    }
  },
  create: (): Endpoint => {
    return {
      method: 'POST',
      url: `meetings/add`,
      errorHandler: baseErrorHandler,
    }
  },
  edit: (meetingId: string): Endpoint => {
    return {
      method: 'PATCH',
      url: `meetings/edit/${meetingId}`,
      errorHandler: baseErrorHandler,
    }
  },
  getPaymentIntentClientSecret: (meetingId: string): Endpoint => {
    return {
      method: 'GET',
      url: `meetings/get-payment-intent-client-secret-for-meeting/${meetingId}`,
      errorHandler: baseErrorHandler,
    }
  },
  joinFreeMeeting: (meetingId?: string): Endpoint => {
    if (!meetingId) return {}
    return {
      method: 'POST',
      url: `meetings/join-free-meeting/${meetingId}`,
      data: {},
      errorHandler: baseErrorHandler,
    }
  },
  changeStatus: (meetingId: string): Endpoint => {
    return {
      method: 'PATCH',
      url: `meetings/change-status/${meetingId}`,
      errorHandler: baseErrorHandler,
    }
  },
  getIcs: (meetingId?: string): Endpoint => {
    return {
      method: 'GET',
      url: `meetings/get-ics/${meetingId}`,
      errorHandler: baseErrorHandler,
    }
  },
  getLinkAddToGoogleCalendar: (meetingId?: string): Endpoint => {
    return {
      method: 'GET',
      url: `meetings/get-link-add-to-google-calendar/${meetingId}`,
      errorHandler: baseErrorHandler,
    }
  },
  joinQuickMeeting: (userId?: string, meetingRoomPathName?: string): Endpoint => {
    if (!userId) return {}
    return {
      method: 'POST',
      url: `meetings/join-quick-meeting/${userId}`,
      data: {
        ...(meetingRoomPathName ? { meeting_room_path_name: meetingRoomPathName } : {}),
      },
    }
  },
  bookFree: (): Endpoint => {
    return {
      method: 'POST',
      errorHandler: bookMeetingHandler,
      url: `meetings/book-free`,
    }
  },
  initiateBookPaid: (): Endpoint => {
    return {
      method: 'POST',
      url: `meetings/initiate-book-paid`,
      errorHandler: bookMeetingHandler,
    }
  },
  sendEmailInvite: (meetingId: string): Endpoint => {
    return {
      method: 'POST',
      url: `meetings/send-email-invite/${meetingId}`,
      errorHandler: baseErrorHandler,
    }
  },
}

type MeetingPermissionFields = {
  is_moderator?: boolean
  can_jaas_record?: boolean
  can_jaas_stream?: boolean
  can_jaas_transcribe?: boolean
  can_local_record?: boolean
}

export type QuickMeeting = {
  host: QuickMeetingHost
  public_url: string
  meeting_room_path_name?: string | null
  jwt_jaas: string
  room_name: string
  name: string
} & MeetingPermissionFields

export type QuickMeetingData = {
  meeting: QuickMeeting
}
export enum MeetingStatus {
  Active = 'active',
  Inactive = 'inactive',
}

export type ListHosted = {
  meetings: HostedMeeting[]
}
export type HostedMeeting = {
  id: string
  name: string
  description: string
  meeting_details: string
  public_url: string
  meeting_location: Location
  additional_meeting_info: string | null
  price_in_cents: string
  iso_currency_code: string
  is_free: boolean
  start_time: string
  end_time: string
  status: MeetingStatus
  is_paid_by_only_one: boolean
  bookable_service: BookableService
  participants: MeetingParticipant[]
  invitations: MeetingInvitation[]
  host: HostUser
  is_event: boolean
  capacity: number
  meeting_location_is_quickmeet: boolean
  meeting_room: MeetingRoomFromApi | null
}

export type Meeting = {
  id: string
  name: string
  description: string
  meeting_details: string
  public_url: string
  meeting_location: Location
  additional_meeting_info: string | null
  price_in_cents: string
  iso_currency_code: string
  is_free: boolean
  start_time: string
  end_time: string
  status: MeetingStatus
  is_paid_by_only_one: boolean
  has_already_been_paid_by_someone?: boolean
  bookable_service: BookableService | null
  participants: MeetingParticipant[]
  host: HostUser
  room_name?: string
  jwt_jaas?: string
  capacity: number
  cover_image: string | null
  is_event: boolean
  is_user_a_participant?: boolean | null
  taken_event_slots?: number | null
  meeting_location_is_quickmeet: boolean
  meeting_room: MeetingRoomFromApi | null
} & MeetingPermissionFields

export type UpcomingEvent = {
  id: string
  name: string
  meeting_location: Location
  additional_meeting_info: string | null
  price_in_cents: string
  iso_currency_code: string
  is_free: boolean
  start_time: string
  end_time: string
  cover_image: string | null
  price_cents: number
}

export type MeetingData = {
  meeting: Meeting
}

export type InitBookPaidData = BookPaidData & {
  public_url: string
}
export type BookPaidData = {
  client_secret: string
  connected_account: string
}

export async function fetchMeeting(meetingUrl: string): Promise<MeetingData> {
  if (!meetingUrl) {
    throw new Error('Meeting url is not provided')
  }
  const response = await api.request<MeetingData>(MeetingsEndpoints.viewByUrl(meetingUrl))
  return response?.data
}

export async function fetchQuickMeeting(
  userId: string,
  meetingRoomPathName?: string,
): Promise<QuickMeetingData> {
  if (!userId) {
    throw new Error('User id is not provided')
  }
  const response = await api.request<QuickMeetingData>(
    MeetingsEndpoints.joinQuickMeeting(userId, meetingRoomPathName),
  )
  return response?.data
}

export async function listUpcomingProviderEvents(hostId?: string): Promise<UpcomingEvent[]> {
  if (!hostId) {
    throw new Error('UserId is not provided')
  }
  const response = await api.request(MeetingsEndpoints.listUpcomingProviderEvents(hostId))
  return response.data?.events
}

export async function fetchMeetingById(id?: string): Promise<Meeting> {
  if (!id) {
    throw new Error('Id is not provided')
  }
  const response = await api.request(MeetingsEndpoints.view(id))
  return response.data?.meeting
}

export default MeetingsEndpoints
