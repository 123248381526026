import { AxiosError } from 'axios'
import HttpError from 'errors/HttpError'
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react'

import { useApi } from 'config/api'
import { MEETING_LOCATION_TYPE } from 'lib/meeting-location'

import MeetingsEndpoints, {
  Meeting,
  MeetingData,
  QuickMeeting,
  QuickMeetingData,
} from 'services/api/Meetings'
import { BookableService } from 'services/auth/ssp'

type MeetingContext = {
  meeting?: Meeting
  setMeeting: React.Dispatch<React.SetStateAction<Meeting | undefined>>
  viewMeeting: () => Promise<void>
  viewMeetingOrRoom: () => Promise<void>
  // Free
  meetingDetails: string
  joinFreeMeeting: () => Promise<void>
  isFreeMeeting: boolean
  isEvent: boolean
  isBookableEvent: boolean
  isMeetingRoom: boolean
  meetingRoomPathName?: string
  isInternalMeeting: boolean
  isExternalMeeting: boolean
  meetingLocationIsMeetingRoom: boolean
  // Booking
  isBookableService: boolean
  bookingTimes?: BookingTimes
  setBookingTimes: React.Dispatch<React.SetStateAction<BookingTimes | undefined>>
  setMeetingDetails: React.Dispatch<React.SetStateAction<string>>
  bookableService?: BookableService
  bookFreeMeeting: () => Promise<void>
  // Team round robin booking
  teamBookingUserIds?: string[]
  setTeamBookingUserIds: React.Dispatch<React.SetStateAction<string[] | undefined>>
  // Quick
  quickMeeting?: QuickMeeting
  isQuickMeeting: boolean
  joinQuickMeeting: () => Promise<void>
  // Other
  hasJaasToken: boolean
  viewError?: AxiosError | HttpError
}

export const MeetingContext = React.createContext<MeetingContext>(null as any)

export const useMeeting = () => {
  return useContext(MeetingContext)
}

type MeetingContextProviderProps = React.PropsWithChildren<{
  initialMeeting?: Meeting
  initialQuickMeeting?: QuickMeeting
  bookableService?: BookableService
  isQuickMeeting?: boolean
  isBookableService?: boolean
  isBookableEvent?: boolean
  isMeetingRoom?: boolean
  meetingRoomPathName?: string
}>
export type BookingTimes = {
  start_time?: Date
  end_time?: Date
}

export const MeetingContextProvider = ({
  children,
  initialMeeting,
  initialQuickMeeting,
  bookableService,
  isQuickMeeting = false,
  isBookableService = false,
  isBookableEvent = false,
  isMeetingRoom = false,
  meetingRoomPathName,
}: MeetingContextProviderProps) => {
  const [meeting, setMeeting] = useState<Meeting | undefined>(initialMeeting)
  const [quickMeeting, setQuickMeeting] = useState<QuickMeeting | undefined>(initialQuickMeeting)
  const [bookingTimes, setBookingTimes] = useState<BookingTimes | undefined>()
  const [meetingDetails, setMeetingDetails] = useState<string>('')
  const [teamBookingUserIds, setTeamBookingUserIds] = useState<string[] | undefined>()

  const viewPublicUrl = useMemo(
    () => MeetingsEndpoints.viewByUrl(meeting?.public_url),
    [meeting?.public_url],
  )
  const joinQuickEndpoint = useMemo(
    () => MeetingsEndpoints.joinQuickMeeting(quickMeeting?.host?.id, meetingRoomPathName),
    [quickMeeting?.host?.id, meetingRoomPathName],
  )
  const joinFreeEndpoint = useMemo(
    () => MeetingsEndpoints.joinFreeMeeting(meeting?.id),
    [meeting?.id],
  )

  const [{ data: viewMeetingData, error: viewError }, fetchMeeting] = useApi<MeetingData>(
    viewPublicUrl,
    { manual: true },
  )
  const [{ data: quickMeetingData }, joinQuick] = useApi<QuickMeetingData>(joinQuickEndpoint, {
    manual: true,
  })
  const [{ data: joinFreeMeetingData }, joinFree] = useApi<MeetingData>(joinFreeEndpoint, {
    manual: true,
  })
  const [{ data: bookFreeData }, bookFree] = useApi<MeetingData>(MeetingsEndpoints.bookFree(), {
    manual: true,
  })

  const isFreeMeeting = useMemo(() => {
    if (isQuickMeeting) return true
    if (isBookableService && bookableService?.is_free) return true
    if (!meeting) return false
    if (meeting.is_free) return true
    if (meeting.has_already_been_paid_by_someone) return true
    return false
  }, [meeting, isQuickMeeting, isBookableService, bookableService])

  const isEvent = useMemo(() => {
    return !!meeting?.is_event
  }, [meeting])

  const isInternalMeeting = useMemo(() => {
    return meeting?.meeting_location === MEETING_LOCATION_TYPE.INTERNAL_MEETING_LINK.location
  }, [meeting])

  const isExternalMeeting = useMemo(() => {
    return meeting?.meeting_location === MEETING_LOCATION_TYPE.EXTERNAL_MEETING_LINK.location
  }, [meeting])

  const meetingLocationIsMeetingRoom = useMemo(() => {
    return meeting?.meeting_location === MEETING_LOCATION_TYPE.MEETING_ROOM.location
  }, [meeting])

  const hasJaasToken = useMemo(
    () => !!meeting?.jwt_jaas || !!quickMeeting?.jwt_jaas,
    [meeting?.jwt_jaas, quickMeeting?.jwt_jaas],
  )

  // Refresh meeting
  useEffect(() => {
    if (viewMeetingData) {
      setMeeting(viewMeetingData.meeting)
    }
  }, [viewMeetingData])

  // Refresh free meeting
  useEffect(() => {
    if (joinFreeMeetingData) {
      setMeeting(joinFreeMeetingData.meeting)
    }
  }, [joinFreeMeetingData])

  // Refresh booked meeting
  useEffect(() => {
    if (bookFreeData) {
      setMeeting(bookFreeData.meeting)
    }
  }, [bookFreeData])

  // Refresh quick meeting
  useEffect(() => {
    if (quickMeetingData) {
      setQuickMeeting(quickMeetingData.meeting)
    }
  }, [quickMeetingData])

  const joinFreeMeeting = useCallback(async () => {
    if (!isFreeMeeting) return
    await joinFree()
  }, [isFreeMeeting, joinFree])

  const joinQuickMeeting = useCallback(async () => {
    if (!isQuickMeeting) return
    await joinQuick()
  }, [isQuickMeeting, joinQuick])

  const bookFreeMeeting = useCallback(async () => {
    if (!isBookableService) return
    // Initialize free booking request
    if (bookableService?.id && bookingTimes) {
      await bookFree({
        data: {
          ...bookingTimes,
          bookable_service_id: bookableService.id,
          ...(teamBookingUserIds ? { team_booking_user_ids: teamBookingUserIds } : {}),
          meeting_details: meetingDetails,
        },
      })
    }
  }, [isBookableService, bookableService?.id, bookingTimes, meetingDetails])

  const viewMeeting = useCallback(async () => {
    if (isBookableService || isQuickMeeting) return
    try {
      await fetchMeeting()
    } catch (error) {
      // Runtime XHR error will populate viewError
    }
  }, [isQuickMeeting, isBookableService, fetchMeeting])

  const viewMeetingOrRoom = useCallback(async () => {
    await joinQuickMeeting()
    await viewMeeting()
  }, [joinQuickMeeting, viewMeeting])

  const value = useMemo<MeetingContext>(
    () => ({
      bookableService,
      bookFreeMeeting,
      bookingTimes,
      meetingDetails,
      setMeetingDetails,
      hasJaasToken,
      isBookableService,
      isInternalMeeting,
      isExternalMeeting,
      meetingLocationIsMeetingRoom,
      isEvent,
      isBookableEvent,
      isFreeMeeting,
      isQuickMeeting,
      isMeetingRoom,
      meetingRoomPathName,
      joinFreeMeeting,
      joinQuickMeeting,
      meeting,
      quickMeeting,
      setBookingTimes,
      setMeeting,
      viewError,
      viewMeeting,
      viewMeetingOrRoom,
      teamBookingUserIds,
      setTeamBookingUserIds,
    }),
    [
      bookableService,
      bookFreeMeeting,
      bookingTimes,
      meetingDetails,
      setMeetingDetails,
      hasJaasToken,
      isBookableService,
      isInternalMeeting,
      isExternalMeeting,
      meetingLocationIsMeetingRoom,
      isFreeMeeting,
      isEvent,
      isBookableEvent,
      isQuickMeeting,
      isMeetingRoom,
      meetingRoomPathName,
      joinFreeMeeting,
      joinQuickMeeting,
      meeting,
      quickMeeting,
      viewError,
      viewMeeting,
      viewMeetingOrRoom,
      teamBookingUserIds,
      setTeamBookingUserIds,
    ],
  ) //added to avoid multiple rerenders
  return <MeetingContext.Provider value={value}>{children}</MeetingContext.Provider>
}
